body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face{
  font-family: "Open Sans";
  src: local("OpenSans-LightItalic"),
  url("./assets/fonts/OpenSans/OpenSans-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
}

@font-face{
  font-family: "Open Sans";
  src: local("OpenSans-Light"),
  url("./assets/fonts/OpenSans/OpenSans-Light.ttf");
  font-style: normal;
  font-weight: 300;
}

@font-face{
  font-family: "Open Sans";
  src: local("OpenSans-Regular"),
  url("./assets/fonts/OpenSans/OpenSans-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}

@font-face{
  font-family: "Open Sans";
  src: local("OpenSans-SemiBold"),
  url("./assets/fonts/OpenSans/OpenSans-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
}

@font-face{
  font-family: "Open Sans";
  src: local("OpenSans-Bold"),
  url("./assets/fonts/OpenSans/OpenSans-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}

@font-face{
  font-family: "Open Sans";
  src: local("OpenSans-ExtraBold"),
  url("./assets/fonts/OpenSans/OpenSans-ExtraBold.ttf");
  font-style: normal;
  font-weight: 800;
}
